/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';

export interface IADDUserAcquisition {
    origin: string,
    store: string,
    count: number,
}

// reference : https://zoiehealth.azurewebsites.net/swagger/index.html
export interface IUserAcquisition{
    id: number,
    origin: string,
    store: string,
    count: number,
    dateAdded: string,
}

export interface IInfluencer{
  influencerId: string,
  apple: number,
  android: number,
  other: number
}

export interface TermsState {
  influencersAcquisitions: IInfluencer[];
  influencersAcquisitionsLoadingStatus: ILoadStatus;
  userAcquisitions: IUserAcquisition[];
  userAcquisitionsLoadingStatus: ILoadStatus;
}

const initialState: TermsState = {
  influencersAcquisitions: [],
  userAcquisitions: [],
  influencersAcquisitionsLoadingStatus: Status.Idle,
  userAcquisitionsLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchUserAcquisitions = createAsyncThunk(
  'userAcquisitions/fetchUserAcquisitions',
  async () => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get('/UserAcquisition/',
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const fetchInfluencerAcquisitions = createAsyncThunk(
  'userAcquisitions/fetchInfluencerAcquisitions',
  async () => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get('/UserAcquisition/short-report/',
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addUserAcquisition = createAsyncThunk(
  'members/addUserAcquisition',
  async (body:IADDUserAcquisition) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/UserAcquisition', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated permissions');
        }
      }).catch(() => console.log('Unsuccessfully updated permissions'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export interface IEditUserAcquisition{
  body:IADDUserAcquisition;
  entityId : number;
}
export const editUserAcquisition = createAsyncThunk(
  'userAcquisitions/editUserAcquisition',
  async (payload:IEditUserAcquisition) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/UserAcquisition/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated UserAcquisition');
        }
      }).catch(() => console.log('Unsuccessfully updated UserAcquisition'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IDeleteUserAcquisition{
  entityId : number;
}
export const deleteUserAcquisition = createAsyncThunk(
  'blogs/editBlog',
  async (payload:IDeleteUserAcquisition) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.delete(`/UserAcquisition/${payload.entityId}`,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully deleted UserAcquisition');
        }
      }).catch(() => console.log('Unsuccessfully deleted UserAcquisition'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const userAcquisitionSlice = createSlice({
  name: 'userAcquisitions',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAcquisitions.pending, (state) => {
        state.userAcquisitionsLoadingStatus = Status.Loading;
      })
      .addCase(fetchUserAcquisitions.fulfilled, (state, action) => {
        state.userAcquisitionsLoadingStatus = Status.Idle;
        state.userAcquisitions = action.payload;
      })
      .addCase(fetchInfluencerAcquisitions.pending, (state) => {
        state.influencersAcquisitionsLoadingStatus = Status.Loading;
      })
      .addCase(fetchInfluencerAcquisitions.fulfilled, (state, action) => {
        state.influencersAcquisitionsLoadingStatus = Status.Idle;
        state.influencersAcquisitions = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = userAcquisitionSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectUserAcquisitions = (state: RootState) => state.userAcquisitions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectInfluencersAcquisitions = (state: RootState) => state.influencersAcquisitions;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default userAcquisitionSlice.reducer;
