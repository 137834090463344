/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { Status } from '../../constants/enums';
import { ILoadStatus } from '../../constants/interfaces';
import { IAddFaq } from '../../components/Faqs/Faqs';
import { RootState } from '../../redux/store';

export interface IFaq {
  id: number;
  faqQuestion: string;
  faqAnswer: string;
}

export interface IDeleteFaq {
  id: string;
}
interface IEditFaq{
  body:IAddFaq;
  entityId : number;
}

export interface FaqState {
  faqs: IFaq[];
  faqsLoadingStatus: ILoadStatus;
}

const initialState: FaqState = {
  faqs: [],
  faqsLoadingStatus: Status.Idle,
};

export const fetchFaqs = createAsyncThunk('faq/fetchFaqs', async () => {
  const base64String = (await localStorage.getItem('authKey')) ?? '';
  let response: any[] = [];
  await APP_URL.get('/Faq', {
    headers: { Authorization: `Basic ${base64String}` },
  })
    .then((res) => {
      if (res.status === 200) {
        response = res.data;
      }
    })
    .catch(() => {});
  // The value we return becomes the `fulfilled` action payload
  return response;
});

export const addFaq = createAsyncThunk(
  'faq/addFaq',
  async (body:IAddFaq) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/faq', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully added faq');
        }
      }).catch(() => console.log('Unsuccessfully added faq'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const deleteFaq = createAsyncThunk(
  'faq/deleteFaq',
  async (body:IDeleteFaq) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.delete(`/Faq/${body.id}/`,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          response = res.data;
        }
      }).catch((res) => { response = res.data; });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const editFaq = createAsyncThunk(
  'faq/editFaq',
  async (payload:IEditFaq) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/faq/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated circle');
          // window.location.href = '/contentmanagement';
        }
      }).catch(() => console.log('Unsuccessfully updated circle'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const faqsSlice = createSlice({
  name: 'faqs',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchFaqs.pending, (state) => {
      state.faqsLoadingStatus = Status.Loading;
    })
      .addCase(fetchFaqs.fulfilled, (state, action) => {
        state.faqsLoadingStatus = Status.Idle;
        state.faqs = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = faqsSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectFaqs = (state: RootState) => state.faqs;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default faqsSlice.reducer;
