// eslint-disable-next-line import/no-anonymous-default-export
import SideBarLogo from './sidebar/SideBarLogo.svg';
import HomeIcon from './sidebar/menuitems/HomeIcon.svg';
import UserMgmtIcon from './sidebar/menuitems/UserMgmtIcon.svg';
import StatisticsIcon from './sidebar/menuitems/StatisticsIcon.svg';
import ContentMgmtIcon from './sidebar/menuitems/ContentMgmtIcon.svg';
import CalendarIcon from './sidebar/menuitems/CalendarIcon.svg';
import HomeButton from './sidebar/menuitems/HomeButton.svg';
import LandingBackground from './background.svg';
import LandingLogo from './LandingLogo.svg';
import GoogleLogo from './GoogleLogo.svg';
import HomeIconActive from './sidebar/menuitems/HomeIconActive.svg';
import UserMgmtIconActive from './sidebar/menuitems/UserMgmtActive.svg';
import CalendarIconActive from './sidebar/menuitems/CalendarActive.svg';
import ContentMgmtIconActive from './sidebar/menuitems/ContentMgmtActive.svg';
import StatisticsIconActive from './sidebar/menuitems/StatisticsActive.svg';
import ActiveLine from './sidebar/menuitems/ActiveLine.svg';
import InactiveLine from './sidebar/menuitems/InactiveLine.svg';
import SampleProfilePicture from './SampleProfilePicture.svg';
import ValidNotificationIcon from './ValidNotificationIcon.svg';
import TopMenuIcon from './TopMenuIcon.svg';
import FlowerSpinner from './flowerSpinner.svg';
import Spinner from './Spinner.svg';
import SearchIcon from './SearchIcon.svg';
import ScreenShot from './ScreenShot.svg';
import VideoBackgroundImg from './videoBackgroundImg.svg';
import ScreenShot2 from './ScreenShot2.svg';

// Modal Icons
import NewMember from './AddNewMember.svg';
import NewCompany from './AddNewCompany.svg';
import BulkIcon from './BulkIcon.svg';

import SampleProfileImage from './SampleProfileImage.svg';
import appGoalsImage from './appGoalsImage.svg';
import MotherImage from './motherImage.svg';
import SocialMediaImage from './socialMediaImage.svg';
import MyCard from './MyCard.svg';
import CalendarPlus from './consultations.svg';
import Events from './shape.svg';
import ShoppingCart from './ShoppingCart.svg';
import Gift from './Gift.svg';

import Pen from './pen.svg';
import Bin from './DeleteIcon.svg';
import Capsule from './capsule.svg';
import Motherhood from './Motherhood.svg';
import Pregnancy from './Pregnancy.svg';
import Fertility from './Fertility.svg';

import PcosOrEndo from './Pcos_Endo.svg';
import MentalHealth from './mental_health.svg';
import Wellness from './wellness.svg';

import PMS from './pms.svg';
import Nursing from './nursing.svg';

import QuestionsLogo from './LightningBolt.svg';
import VideoLogo from './PlayLogo.svg';
import AudioLogo from './AudioLogo.svg';
import PdfLogo from './BookLogo.svg';

import VideoTime from './videotime.svg';
import ReadingTime from './readingtime.svg';
import Audio from './audio.svg';
import Ebooks from './ebooks.svg';
import Sections from './sections.svg';
import Video from './video.svg';
import Activities from './activities.svg';
import Learners from './learners.svg';
import NewMothersWellness from './NewMothersWellness.svg';
import NursingBorn from './NursingBorn.svg';

import Baby from './baby.svg';
import BabyClothes from './babyClothes.svg';

import MenstualSeries from './menstrualSeries.svg';

import Mothers2Mothers from './mothers2mothers.svg';
import Wagrau from './wagrau.svg';

import HomeBanner from './HomeBanner.svg';

import Menstruation1 from './menstruation_1.svg';
import Menstruation2 from './menstruation_2.svg';
import Uterus from './uterus_1.svg';
import ContraceptivePills from './contraceptive-pills.svg';

import ZoieProfileImage from './ZoieProfileImage.svg';

export const Images = {
  SideBarLogo,
  HomeIcon,
  UserMgmtIcon,
  StatisticsIcon,
  ContentMgmtIcon,
  CalendarIcon,
  HomeButton,
  LandingBackground,
  LandingLogo,
  GoogleLogo,
  HomeIconActive,
  UserMgmtIconActive,
  CalendarIconActive,
  ContentMgmtIconActive,
  StatisticsIconActive,
  ActiveLine,
  InactiveLine,
  SampleProfilePicture,
  ValidNotificationIcon,
  TopMenuIcon,
  NewMember,
  BulkIcon,
  NewCompany,
  SampleProfileImage,
  ScreenShot,
  ScreenShot2,
  VideoBackgroundImg,
  ProfileIcons: {
    appGoalsImage,
    MotherImage,
    SocialMediaImage,
    MyCard,
    CalendarPlus,
    Events,
    ShoppingCart,
    Gift,
  },
  CircleIcons: {
    Capsule,
    Motherhood,
    Pregnancy,
    Fertility,
  },
  PersonalisedPathwaysIcon: {
    QuestionsLogo,
    VideoLogo,
    AudioLogo,
    PdfLogo,
  },
  SubCircleIcons: {
    PcosOrEndo,
    MentalHealth,
    Wellness,
  },
  PersonalizedPathPathwaysIcons: {
    PMS,
    VideoTime,
    ReadingTime,
    Audio,
    Ebooks,
    Sections,
    Video,
    Activities,
    Learners,
    NewMothersWellness,
    NursingBorn,
  },
  BlogsIcons: {
    Nursing,
    Baby,
    BabyClothes,
  },
  PodCastsIcons: {
    MenstualSeries,
  },
  GiveIcons: {
    Mothers2Mothers,
    Wagrau,
  },
  GeneralIcons: {
    HomeBanner,
    ZoieBadges: {
      Menstruation1,
      Menstruation2,
      Uterus,
      ContraceptivePills,
    },
    ZoieProfileImage,
  },
  FlowerSpinner,
  Spinner,
  Pen,
  Bin,
  SearchIcon,
};

export default Images;
