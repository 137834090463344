/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';

export interface IADDLegalDocument {
    docName:string;
    docGuid: string| null;
    docUrl:string| null;
    addedBy: number;
}

// reference : https://zoiehealth.azurewebsites.net/swagger/index.html
export interface ILegalDocument {
   id: number;
   docName:string;
   createdAt:string;
   docGuid: string| null;
   docUrl:string| null;
   addedBy: number;
}

export interface TermsState {
  legalDocuments: ILegalDocument[];
  legalDocumentsLoadingStatus: ILoadStatus;
}

const initialState: TermsState = {
  legalDocuments: [],
  legalDocumentsLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchlegalDocument = createAsyncThunk(
  'legalDocuments/fetchlegalDocuments',
  async () => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get('/legalDoc/',
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addLegalDocument = createAsyncThunk(
  'legalDocument/addLegalDocument',
  async (body:IADDLegalDocument) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/legalDoc', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully added Legal Document');
        }
      }).catch(() => console.log('Unsuccessfully added Legal Document'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IEditLegalDocument{
  body:IADDLegalDocument;
  entityId : number;
}
export const editLegalDocument = createAsyncThunk(
  'legalDocument/editlegalDocument',
  async (payload:IEditLegalDocument) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/legalDoc/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated legal Document');
        }
      }).catch(() => console.log('Unsuccessfully updated legal Document'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export interface IDeleteLegalDocument{
  entityId : number;
}
export const deletelegalDocument = createAsyncThunk(
  'legalDocument/deletelegalDocument',
  async (payload:IDeleteLegalDocument) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.delete(`/legalDoc/${payload.entityId}`,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully deleted Legal Document');
        }
      }).catch(() => console.log('Unsuccessfully deleted Legal Document'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const legalDocumentSlice = createSlice({
  name: 'legalDocument',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchlegalDocument.pending, (state) => {
        state.legalDocumentsLoadingStatus = Status.Loading;
      })
      .addCase(fetchlegalDocument.fulfilled, (state, action) => {
        state.legalDocumentsLoadingStatus = Status.Idle;
        state.legalDocuments = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = legalDocumentSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectLegalDocument = (state: RootState) => state.legalDocuments;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default legalDocumentSlice.reducer;
