/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';

export interface IADDStage {
  stageName:string;
  logoUrl: string,
  subHeader: any,
  stageDesc:string;
}
// reference : https://zoiehealth.azurewebsites.net/swagger/index.html
export interface IStage {
   id: number;
   stageName:string;
   stageDesc:string;
   createdAt:string;
   subHeader:any,
   logoUrl: string,
   stageCircles: any[],
   userStages: any[]
}

export interface TermsState {
  stages: IStage[];
  stagesLoadingStatus: ILoadStatus;
}

const initialState: TermsState = {
  stages: [],
  stagesLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchStages = createAsyncThunk(
  'stages/fetchStages',
  async () => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get('/stage/',
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addStage = createAsyncThunk(
  'members/addStage',
  async (body:IADDStage) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/stage', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated Stages');
        }
      }).catch(() => console.log('Unsuccessfully updated Stages'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IEditStage{
  body:IADDStage;
  entityId : number;
}
export const editStage = createAsyncThunk(
  'members/editStage',
  async (payload:IEditStage) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/stage/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated stage');
          // window.location.href = '/contentmanagement';
        }
      }).catch(() => console.log('Unsuccessfully updated Stage'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const stagesSlice = createSlice({
  name: 'stages',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchStages.pending, (state) => {
        state.stagesLoadingStatus = Status.Loading;
      })
      .addCase(fetchStages.fulfilled, (state, action) => {
        state.stagesLoadingStatus = Status.Idle;
        state.stages = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = stagesSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectStages = (state: RootState) => state.stages;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default stagesSlice.reducer;
