/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';

export interface IAddGuideTopic {
    'id': number,
    'guideId': number,
    'topicId': number,
}

export interface IAddMultipleGuideTopic {
    'guideTopics': IAddGuideTopic[],
}

export interface IDeleteMultipleGuideTopic {
  'guideId': number,
  'topicIds': number[],
}

export interface IGuideTopic {
    'id': number,
    'guideId': number,
    'topicId': number,
}

export interface TermsState {
  guideTopics: IGuideTopic[];
  guideTopicsLoadingStatus: ILoadStatus;
}

const initialState: TermsState = {
  guideTopics: [],
  guideTopicsLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchGuideTopics = createAsyncThunk(
  'guideTopic/fetchGuideTopics',
  async () => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get('/guideTopic/',
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
          console.log(response);
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addGuideTopic = createAsyncThunk(
  'guideTopics/addGuideTopic',
  async (body:IAddGuideTopic) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/guideTopic', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully added GuideTopic');
        }
      }).catch(() => console.log('Unsuccessfully added GuideTopic'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addGuideTopics = createAsyncThunk(
  'guideTopics/addGuideTopics',
  async (body:IAddMultipleGuideTopic) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/guideTopic/multiple', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully added GuideTopic');
        }
      }).catch(() => console.log('Unsuccessfully added GuideTopic'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const deleteGuideTopics = createAsyncThunk(
  'guideTopics/deleteGuideTopic',
  async (body:IDeleteMultipleGuideTopic) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post(`/guideTopic/delete-multiple/${body.guideId}`, body.topicIds,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully deleted guideTopics');
        }
      }).catch(() => console.log('Unsuccessfully deleted guideTopics'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IEditGuideTopic{
  body:IAddGuideTopic;
  entityId : number;
}
export const editGuideTopic = createAsyncThunk(
  'guideTopics/editGuideTopic',
  async (payload:IEditGuideTopic) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/guideTopic/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated guideTopic');
        }
      }).catch(() => console.log('Unsuccessfully updated guideTopic'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IDeleteGuideTopics{
  entityId : number;
}
export const deleteGuideTopic = createAsyncThunk(
  'guideTopics/editGuideTopic',
  async (payload:IDeleteGuideTopics) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.delete(`/guideTopic/${payload.entityId}`,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully deleted GuideTopic');
        }
      }).catch(() => console.log('Unsuccessfully deleted GuideTopic'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const guideTopicsSlice = createSlice({
  name: 'guideTopics',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchGuideTopics.pending, (state) => {
        state.guideTopicsLoadingStatus = Status.Loading;
      })
      .addCase(fetchGuideTopics.fulfilled, (state, action) => {
        state.guideTopicsLoadingStatus = Status.Idle;
        state.guideTopics = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = guideTopicsSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectGuideTopics = (state: RootState) => state.guideTopics;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default guideTopicsSlice.reducer;
