/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { Status } from '../../constants/enums';
import { ILoadStatus } from '../../constants/interfaces';
import { RootState } from '../../redux/store';
import { isStatusOK } from './emergencyContactsSlice';

const ENDPOINT = 'StreamChat';
const resourceText = 'StreamChat';
export const AUTH_KEY = localStorage.getItem('authKey') ?? '';
export const headers = { Authorization: `Basic ${AUTH_KEY}` };

export interface IStreamChatSchema extends IUserMessage {
  userMessages: IUserMessage[],
}
export interface IUserMessage {
  practitionerName: string
  practitionerLastName: string
  practitionerEmailAddress: string
  messages: number
}
export interface IAddStreamChat extends IStreamChatSchema {}

export interface StreamChatState {
  streamChat: IStreamChatSchema | null;
  streamChatLoadingStatus: ILoadStatus;
}

const initialState: StreamChatState = {
  streamChat: null,
  streamChatLoadingStatus: Status.Idle,
};

export const fetchStreamChat = createAsyncThunk(
  `${ENDPOINT}/fetchStreamChat`,
  async () => {
    let response: any = [];
    await APP_URL.get(`/${ENDPOINT}`, {
      headers,
    })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      })
      .catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addStreamChat = createAsyncThunk(
  `${ENDPOINT}/addStreamChat`,
  async (body:IStreamChatSchema) => {
    const response:any[] = [];
    await APP_URL.post(`/${ENDPOINT}`, body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully added ${resourceText}`);
        }
      }).catch(() => {
        throw new Error('error');
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const streamChatSlice = createSlice({
  name: ENDPOINT,
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchStreamChat.pending, (state) => {
      state.streamChatLoadingStatus = Status.Loading;
    })
      .addCase(fetchStreamChat.fulfilled, (state, action) => {
        state.streamChatLoadingStatus = Status.Idle;
        state.streamChat = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = streamChatSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectStreamChat = (state: RootState) => state.streamChat;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default streamChatSlice.reducer;
