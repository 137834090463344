import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_ZOEI_BASEURL;
export const APP_URL = axios.create({
  baseURL: BASE_API_URL,
  // auth: {"password":"", "username":""}
});
export const APP_LOCAL_URL = axios.create({
  baseURL: 'https://localhost:20592/api',
});

// APP_URL.get("userauth/authorize-user", { headers: { "Authorization": btoa("email:authsystemid")}})
