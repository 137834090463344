/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';

export interface IAddPollResult {
    userId: number,
    pollOptionId: number,
    dateAnswered: string,
    poll: any,
    pollOption:any,
    user: any,
}

// reference : https://zoiehealth.azurewebsites.net/swagger/index.html
export interface IPollResult {
    id: number,
    pollId: number,
    userId: number,
    pollOptionId: number,
    dateAnswered: string,
    poll: any,
    pollOption:any,
    user: any,
}

export interface TermsState {
  pollResult: IPollResult[];
  pollResultsLoadingStatus: ILoadStatus;
}

const initialState: TermsState = {
  pollResult: [],
  pollResultsLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchPollResult = createAsyncThunk(
  'pollResult/fetchPollResult',
  async (id?: number) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get(`/pollResult/${id ?? ''}`,
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addPollResult = createAsyncThunk(
  'pollResult/addPollResult',
  async (body:IAddPollResult) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/pollResult', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated Poll Result');
        }
      }).catch(() => console.log('Unsuccessfully updated Poll Result'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IEditPollResult{
  body:IAddPollResult;
  entityId : number;
}
export const editPollResult = createAsyncThunk(
  'pollResult/editPollResult',
  async (payload:IEditPollResult) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/pollResult/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated poll Result');
        }
      }).catch(() => console.log('Unsuccessfully updated poll Result'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export interface IDeletePollResult{
  entityId : number;
}
export const deletePollResult = createAsyncThunk(
  'pollResult/deletePollResult',
  async (payload:IDeletePollResult) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.delete(`/pollResult/${payload.entityId}`,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully deleted poll Result');
        }
      }).catch(() => console.log('Unsuccessfully deleted poll Result'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const pollResultSlice = createSlice({
  name: 'pollResult',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchPollResult.pending, (state) => {
        state.pollResultsLoadingStatus = Status.Loading;
      })
      .addCase(fetchPollResult.fulfilled, (state, action) => {
        state.pollResultsLoadingStatus = Status.Idle;
        state.pollResult = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = pollResultSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectPollResult = (state: RootState) => state.pollResult;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default pollResultSlice.reducer;
