/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { Status } from '../../constants/enums';
import { ILoadStatus } from '../../constants/interfaces';
import { RootState } from '../../redux/store';
import { IAddEvent } from '../../pages/Calendar/Calendar';

export interface IEvent {
    id: number,
    eventName: string,
    eventStatus: boolean,
    startDate: number,
    endDate: number,
    circleId: number,
    addedBy: number,
    createdAt: string,
    eventDescription: string,
    eventLink: string,
    eventImage: string,
    eventHost: string,
    eventHostDetails: string,
}

export interface IDeleteEvent {
  id: number;
}
interface IEditEvent{
  body: IAddEvent;
  entityId : number;
}

export interface AnnouncementState {
  events: IEvent[];
  eventsLoadingStatus: ILoadStatus;
}

const initialState: AnnouncementState = {
  events: [],
  eventsLoadingStatus: Status.Idle,
};

export const fetchEvents = createAsyncThunk('event/fetchEvents', async () => {
  const base64String = (await localStorage.getItem('authKey')) ?? '';
  let response: any[] = [];
  await APP_URL.get('/event', {
    headers: { Authorization: `Basic ${base64String}` },
  })
    .then((res) => {
      if (res.status === 200) {
        response = res.data;
      }
    })
    .catch(() => {});
  // The value we return becomes the `fulfilled` action payload
  return response;
});

export const addEvent = createAsyncThunk(
  'event/addEvent',
  async (body:IAddEvent) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/event', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully added event');
        }
      }).catch((e) => console.log(e));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const deleteEvent = createAsyncThunk(
  'event/deleteEvent',
  async (body:IDeleteEvent) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.delete(`/event/${body.id}/`,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          response = res.data;
        }
      }).catch((res) => { response = res.data; });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const editEvent = createAsyncThunk(
  'event/editEvent',
  async (payload:IEditEvent) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/event/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated event');
        }
      }).catch(() => console.log('Unsuccessfully updated event'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchEvents.pending, (state) => {
      state.eventsLoadingStatus = Status.Loading;
    })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.eventsLoadingStatus = Status.Idle;
        state.events = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = eventsSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectEvents = (state: RootState) => state.events;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default eventsSlice.reducer;
