/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { Status } from '../../constants/enums';
import { ILoadStatus } from '../../constants/interfaces';
import { RootState } from '../../redux/store';
import { isStatusOK } from './emergencyContactsSlice';

const ENDPOINT = 'questionOption';
const resourceText = 'QuestionOption';
export const AUTH_KEY = localStorage.getItem('authKey') ?? '';
export const headers = { Authorization: `Basic ${AUTH_KEY}` };

export interface IQuestionOptionSchema {
    id?: number
    optionText: string
    questionId: number
}

export interface IDeleteQuestionOption {
  id: number;
}

export interface IEditQuestionOption {
  body: IQuestionOptionSchema;
  entityId : number;
}

export interface QuestionOptionState {
  questionOption: IQuestionOptionSchema[];
  questionOptionLoadingStatus: ILoadStatus;
}

const initialState: QuestionOptionState = {
  questionOption: [],
  questionOptionLoadingStatus: Status.Idle,
};

export const fetchQuestionOption = createAsyncThunk(
  `${ENDPOINT}/fetchQuestionOption`, async (id? : number| null) => {
    let response: IQuestionOptionSchema[] = [];
    await APP_URL.get(`/${ENDPOINT}${id ? `/${id}` : ''}`, {
      headers,
    })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      })
      .catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const deleteQuestionOption = createAsyncThunk(
  `${ENDPOINT}/deleteQuestionOption`,
  async (body:IDeleteQuestionOption) => {
    let response:any[] = [];
    await APP_URL.delete(`/${ENDPOINT}/${body.id}/`,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      }).catch((res) => { response = res.data; });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addQuestionOption = createAsyncThunk(
  `${ENDPOINT}/addQuestionOption`,
  async (payload:IQuestionOptionSchema) => {
    const response:any[] = [];
    await APP_URL.post(`/${ENDPOINT}`, payload,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully added ${resourceText}`);
        }
      }).catch(() => console.log(`Unsuccessfully added ${resourceText}`));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const editQuestionOption = createAsyncThunk(
  `${ENDPOINT}/editQuestionOption`,
  async (payload:IEditQuestionOption) => {
    const response:any[] = [];
    await APP_URL.put(`/${ENDPOINT}/${payload.entityId}`, payload.body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully updated ${resourceText}`);
        }
      }).catch(() => console.log(`Unsuccessfully ${resourceText}`));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const questionOptionSlice = createSlice({
  name: ENDPOINT,
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchQuestionOption.pending, (state) => {
      state.questionOptionLoadingStatus = Status.Loading;
    })
      .addCase(fetchQuestionOption.fulfilled, (state, action) => {
        state.questionOptionLoadingStatus = Status.Idle;
        state.questionOption = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = questionOptionSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectQuestionOption = (state: RootState) => state.questionOption;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default questionOptionSlice.reducer;
