/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { Status } from '../../constants/enums';
import { ILoadStatus } from '../../constants/interfaces';
import { RootState } from '../../redux/store';
import { isStatusOK } from './emergencyContactsSlice';

const ENDPOINT = 'donation';
const resourceText = 'Donation';
export const AUTH_KEY = localStorage.getItem('authKey') ?? '';
export const headers = { Authorization: `Basic ${AUTH_KEY}` };

interface IDonationSchema {
  foundationName: string
  foundationDescription: string
  foundationImage: string
  priceId: string
}

export interface IDonation extends IDonationSchema {
    id: number,
}

export interface IAddDonation extends IDonationSchema {
    id: number,
}

export interface IDeleteDonation {
  entityId: number;
}
interface IEditDonation {
  body: IAddDonation;
  entityId : number;
}

export interface DonationState {
  donation: IDonation[];
  donationLoadingStatus: ILoadStatus;
}

const initialState: DonationState = {
  donation: [],
  donationLoadingStatus: Status.Idle,
};

export const fetchDonation = createAsyncThunk(
  `${ENDPOINT}/fetchDonation`, async (id? : number| null) => {
    let response: IDonation[] = [];
    await APP_URL.get(`/${ENDPOINT}${id ? `/${id}` : ''}`, {
      headers,
    })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      })
      .catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addDonation = createAsyncThunk(
  `${ENDPOINT}/addDonation`,
  async (body:IAddDonation) => {
    const response:any[] = [];
    await APP_URL.post(`/${ENDPOINT}`, body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully added ${resourceText}`);
        }
      }).catch(() => {
        throw new Error('error');
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const deleteDonation = createAsyncThunk(
  `${ENDPOINT}/deleteDonation`,
  async (body:IDeleteDonation) => {
    let response:any[] = [];
    await APP_URL.delete(`/${ENDPOINT}/${body.entityId}/`,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      }).catch((res) => { response = res.data; });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const editDonation = createAsyncThunk(
  `${ENDPOINT}/editDonation`,
  async (payload:IEditDonation) => {
    const response:any[] = [];
    await APP_URL.put(`/${ENDPOINT}/${payload.entityId}`, payload.body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully updated ${resourceText}`);
        }
      }).catch(() => console.log(`Unsuccessfully ${resourceText}`));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const donationSlice = createSlice({
  name: ENDPOINT,
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchDonation.pending, (state) => {
      state.donationLoadingStatus = Status.Loading;
    })
      .addCase(fetchDonation.fulfilled, (state, action) => {
        state.donationLoadingStatus = Status.Idle;
        state.donation = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = donationSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectDonation = (state: RootState) => state.donation;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default donationSlice.reducer;
