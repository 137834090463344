/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { Status } from '../../constants/enums';
import { ILoadStatus } from '../../constants/interfaces';
import { RootState } from '../../redux/store';

const ENDPOINT = 'emergencyContact';
const resourceText = 'emergency Assistant';
export const AUTH_KEY = localStorage.getItem('authKey') ?? '';
const headers = { Authorization: `Basic ${AUTH_KEY}` };

export function isStatusOK(statusCode: number) {
  return statusCode >= 200 && statusCode < 300;
}

export interface IEmergencyContact {
    id: number,
    description: string,
    phoneNumber : string,
    title: string,
}

export interface IAddEmergencyContact {
    description: string,
    phoneNumber : string,
    title: string,
}

export interface IDeleteEmergencyContact {
  id: number;
}
interface IEditEmergencyContact {
  body: IAddEmergencyContact;
  entityId : number;
}

export interface EmergencyContactState {
  emergencyContacts: IEmergencyContact[];
  emergencyContactLoadingStatus: ILoadStatus;
}

const initialState: EmergencyContactState = {
  emergencyContacts: [],
  emergencyContactLoadingStatus: Status.Idle,
};

export const fetchEmergencyContacts = createAsyncThunk(
  `${ENDPOINT}/fetchEmergencyContact`, async (id? : number| null) => {
    let response: any[] = [];
    await APP_URL.get(`/${ENDPOINT}${id ? `/${id}` : ''}`, {
      headers,
    })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      })
      .catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addEmergencyContact = createAsyncThunk(
  `${ENDPOINT}/addEmergencyContact`,
  async (body:IAddEmergencyContact) => {
    const response:any[] = [];
    await APP_URL.post(`/${ENDPOINT}`, body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully added ${resourceText}`);
        }
      }).catch((e) => console.log(e));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const deleteEmergencyContact = createAsyncThunk(
  `${ENDPOINT}/deleteEmergencyContact`,
  async (body:IDeleteEmergencyContact) => {
    let response:any[] = [];
    await APP_URL.delete(`/${ENDPOINT}/${body.id}/`,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      }).catch((res) => { response = res.data; });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const editEmergencyContact = createAsyncThunk(
  `${ENDPOINT}/editEmergencyContact`,
  async (payload:IEditEmergencyContact) => {
    const response:any[] = [];
    await APP_URL.put(`/${ENDPOINT}/${payload.entityId}`, payload.body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully updated ${resourceText}`);
        }
      }).catch(() => console.log(`Unsuccessfully ${resourceText}`));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const emergencyContactsSlice = createSlice({
  name: 'emergencyContacts',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchEmergencyContacts.pending, (state) => {
      state.emergencyContactLoadingStatus = Status.Loading;
    })
      .addCase(fetchEmergencyContacts.fulfilled, (state, action) => {
        state.emergencyContactLoadingStatus = Status.Idle;
        state.emergencyContacts = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = emergencyContactsSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectEmergencyContact = (state: RootState) => state.emergencyContacts;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default emergencyContactsSlice.reducer;
