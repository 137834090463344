/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { Status } from '../../constants/enums';
import { ILoadStatus } from '../../constants/interfaces';
import { RootState } from '../../redux/store';
import { isStatusOK } from './emergencyContactsSlice';

const ENDPOINT = 'courseQuestion';
const resourceText = 'CourseQuestion';
export const AUTH_KEY = localStorage.getItem('authKey') ?? '';
export const headers = { Authorization: `Basic ${AUTH_KEY}` };

interface ICourseQuestionSchema {
    id: number
    questionTitle: string
    courseId: number
    sectionId: number
    dateCreated: string
    order: number
    guid: string
    course: any
    section: any
    answers: any[]
    questionOptions: any[]
}
export interface IAddCourseQuestionSchema {
  id: number
  questionTitle: string
  courseId: number
  sectionId: number
  order: number
}
export interface IAddCourseQuestionOptionSchema {
  questionTitle: string
  courseId: number | undefined,
  sectionId: number | undefined
  order: number
}

export interface IAddCourseQuestionCombined {
  question: IAddCourseQuestionOptionSchema;
  option: any[] | undefined;
}
export interface ICourseQuestion extends ICourseQuestionSchema {
    id: number,
}
export interface ICourseQuestionById extends ICourseQuestionSchema {
  id: number,
  questionOptions: []
  questionId: number
}

export interface IAddCourseQuestion extends ICourseQuestionSchema {}

export interface IDeleteCourseQuestion {
  id: number;
}

interface IEditCourseQuestion {
  body: IAddCourseQuestionSchema;
  entityId : number;
}
export interface IStoredActivityInfo {
  id ?: any;
  storedInfo: any;
  courseId: number
  sectionId: number
  order: number
}
export interface CourseQuestionState {
  coursesQuestion: ICourseQuestion[];
  coursesQuestionById: ICourseQuestionById | null;
  storedActivityValue: IStoredActivityInfo[] ;
  coursesQuestionLoadingStatus: ILoadStatus;
  coursesQuestionByIdLoadingStatus: ILoadStatus;
}

const initialState: CourseQuestionState = {
  coursesQuestion: [],
  coursesQuestionById: null,
  storedActivityValue: [],
  coursesQuestionLoadingStatus: Status.Idle,
  coursesQuestionByIdLoadingStatus: Status.Idle,
};

export const fetchCourseQuestion = createAsyncThunk(
  `${ENDPOINT}/fetchCourseQuestion`, async (id? : number| null) => {
    let response: ICourseQuestion[] = [];
    await APP_URL.get(`/${ENDPOINT}${id ? `/${id}` : ''}`, {
      headers,
    })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      })
      .catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const fetchCourseQuestionById = createAsyncThunk(
  `${ENDPOINT}/fetchCourseQuestionById`, async (id? : number| null) => {
    let response: any = {};
    await APP_URL.get(`/${ENDPOINT}/${id}`, {
      headers,
    })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      })
      .catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const storedActivityInfo = createAsyncThunk(
  `${ENDPOINT}/storedActivityInfo`,
  (ActivityInfoStored : any) => {
    let response:IStoredActivityInfo[] = [];
    response = ActivityInfoStored;
    console.log('hit endpoint:', ActivityInfoStored);
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addCourseQuestion = createAsyncThunk(
  `${ENDPOINT}/addCourseQuestion`,
  async (body:IAddCourseQuestionSchema) => {
    const response:any[] = [];
    await APP_URL.post(`/${ENDPOINT}`, body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully added ${resourceText}`);
        }
      }).catch(() => {
        throw new Error('error');
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const addCourseQuestionOptions = createAsyncThunk(
  `${ENDPOINT}/addCourseQuestionOptions`,
  async (body:IAddCourseQuestionCombined) => {
    const response:any[] = [];
    await APP_URL.post(`/${ENDPOINT}/AddQuestionOptions`, body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully added ${resourceText}`);
        }
      }).catch(() => {
        throw new Error('error');
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const deleteCourseQuestion = createAsyncThunk(
  `${ENDPOINT}/deleteCourseQuestion`,
  async (body:IDeleteCourseQuestion) => {
    let response:any[] = [];
    await APP_URL.delete(`/${ENDPOINT}/${body.id}/`,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      }).catch((res) => { response = res.data; });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const editCourseQuestion = createAsyncThunk(
  `${ENDPOINT}/editCourseQuestion`,
  async (payload:IEditCourseQuestion) => {
    const response:any[] = [];
    await APP_URL.put(`/${ENDPOINT}/${payload.entityId}`, payload.body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully updated ${resourceText}`);
        }
      }).catch(() => console.log(`Unsuccessfully ${resourceText}`));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const courseQuestionSlice = createSlice({
  name: ENDPOINT,
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchCourseQuestion.pending, (state) => {
      state.coursesQuestionLoadingStatus = Status.Loading;
    })
      .addCase(fetchCourseQuestionById.pending, (state) => {
        state.coursesQuestionByIdLoadingStatus = Status.Loading;
      })
      .addCase(storedActivityInfo.fulfilled, (state, action) => {
        state.storedActivityValue = action.payload;
      })
      .addCase(fetchCourseQuestionById.fulfilled, (state, action) => {
        state.coursesQuestionByIdLoadingStatus = Status.Idle;
        state.coursesQuestionById = action.payload;
      })
      .addCase(fetchCourseQuestion.fulfilled, (state, action) => {
        state.coursesQuestionLoadingStatus = Status.Idle;
        state.coursesQuestion = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = courseQuestionSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectCourseQuestion = (state: RootState) => state.coursesQuestion;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default courseQuestionSlice.reducer;
