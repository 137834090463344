/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';

export interface IADDPodcast {
  podcastTitle:string;
  podcastUrl: string;
  podcastImg:string| null;
  addedBy: number;
}

// reference : https://zoiehealth.azurewebsites.net/swagger/index.html
export interface IPodcast {
   id: number;
   createdAt:string;
   podcastTitle:string;
   podcastUrl: string;
   podcastImg:string| null;
   addedBy: number;
}

export interface TermsState {
  podcasts: IPodcast[];
  podcastsLoadingStatus: ILoadStatus;
}

const initialState: TermsState = {
  podcasts: [],
  podcastsLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchPodcasts = createAsyncThunk(
  'podcasts/fetchPodcasts',
  async () => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get('/podcast/',
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addPodcast = createAsyncThunk(
  'podcasts/addPodcast',
  async (body:IADDPodcast) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/podcast', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          response.push(res);
          console.log('successfully added Podcast');
        }
      }).catch(() => console.log('Unsuccessfully added Podcast'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IEditPodcast{
  body:IADDPodcast;
  entityId : number;
}
export const editPodcast = createAsyncThunk(
  'podcasts/editPodcast',
  async (payload:IEditPodcast) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/podcast/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated Podcast');
        }
      }).catch(() => console.log('Unsuccessfully updated Podcast'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IDeletePodcast{
  entityId : number;
}
export const deletePodcast = createAsyncThunk(
  'podcasts/deletePodcast',
  async (payload:IDeletePodcast) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.delete(`/podcast/${payload.entityId}`,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully deleted Podcast');
        }
      }).catch(() => console.log('Unsuccessfully deleted Podcast'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const podcastsSlice = createSlice({
  name: 'podcasts',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchPodcasts.pending, (state) => {
        state.podcastsLoadingStatus = Status.Loading;
      })
      .addCase(fetchPodcasts.fulfilled, (state, action) => {
        state.podcastsLoadingStatus = Status.Idle;
        state.podcasts = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = podcastsSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectPodcast = (state: RootState) => state.podcasts;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default podcastsSlice.reducer;
