/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { Status } from '../../constants/enums';
import { ILoadStatus } from '../../constants/interfaces';
import { RootState } from '../../redux/store';
import { isStatusOK } from './emergencyContactsSlice';

const ENDPOINT = 'OverallUsageLog';
const resourceText = 'OverallUsageLog';
export const AUTH_KEY = localStorage.getItem('authKey') ?? '';
export const headers = { Authorization: `Basic ${AUTH_KEY}` };

export interface IOverallUsageLogSchema {
    id: number
    interactionCount: number
    dateAdded: string
}
export interface IBodyDatesSchema {
    startDate: string
    endDate: string
}

export interface IOverallUsageLogChat {}

export interface OverallUsageLogState {
  overallUsageLog: IOverallUsageLogSchema[];
  overallUsageLogLoadingStatus: ILoadStatus;
}

const initialState: OverallUsageLogState = {
  overallUsageLog: [],
  overallUsageLogLoadingStatus: Status.Idle,
};

export const fetchOverallUsageLog = createAsyncThunk(
  `${ENDPOINT}/fetchOverallUsageLog`,
  async (body: IBodyDatesSchema) => {
    let response: IOverallUsageLogSchema[] = [];
    await APP_URL.post(`/${ENDPOINT}/getUsageLogData`, body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(body);
          response = res.data;
        }
      })
      .catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addOverallUsageLog = createAsyncThunk(
  `${ENDPOINT}/addOverallUsageLog`,
  async (body:IOverallUsageLogSchema) => {
    const response:any[] = [];
    await APP_URL.post(`/${ENDPOINT}`, body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully added ${resourceText}`);
        }
      }).catch(() => {
        throw new Error('error');
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const overallUsageLogSlice = createSlice({
  name: ENDPOINT,
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchOverallUsageLog.pending, (state) => {
      state.overallUsageLogLoadingStatus = Status.Loading;
    })
      .addCase(fetchOverallUsageLog.fulfilled, (state, action) => {
        state.overallUsageLogLoadingStatus = Status.Idle;
        state.overallUsageLog = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = overallUsageLogSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectOverallUsageLog = (state: RootState) => state.overallUsageLog;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default overallUsageLogSlice.reducer;
