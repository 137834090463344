import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import membersReducer from '../features/data/membersSlice';
import circlesReducer from '../features/data/circlesSlice';
import blogsReducer from '../features/data/blogsSlice';
import faqsReducer from '../features/data/faqSlice';
import announcementsReducer from '../features/data/announcementSlice';
import goalsReducer from '../features/data/goalSlice';
import blogTopicsReducer from '../features/data/blogTopicSlice';
import eventsReducer from '../features/data/eventSlice';
import guidesReducer from '../features/data/guideSlice';
import guideTopicReducer from '../features/data/guideTopicSlice';
import podcastsReducer from '../features/data/podcastsSlice';
import podcastsTopicReducer from '../features/data/podcastsTopicSlice';
import stagesReducer from '../features/data/stagesSlice';
import topicsReducer from '../features/data/topicsSlice';
import stageCircleReducer from '../features/data/stageCircleSlice';
import topicCircleReducer from '../features/data/topicCircleSlice';
import bannersReducer from '../features/data/bannerSlice';
import convosReducer from '../features/data/convosSlice';
import pollInformationReducer from '../features/data/pollInformationSlice';
import pollResultReducer from '../features/data/pollResult';
import pollQuestionOptionReducer from '../features/data/pollQuestionOptionSlice';
import emergencyContactsReducer from '../features/data/emergencyContactsSlice';
import referralsReducer from '../features/data/referralsSlice';
import corporationReducer from '../features/data/corporationSlice';
import courseReducer from '../features/data/courseSlice';
import courseItemReducer from '../features/data/courseItemSlice';
import courseQuestionReducer from '../features/data/courseQuestionSlice';
import questionOptionReducer from '../features/data/questionOptionSlice';
import sectionReducer from '../features/data/sectionSlice';
import streamChatReducer from '../features/data/streamChatSlice';
import overallUsageLogReducer from '../features/data/overallUsageLogSlice';
import circleUsageLogReducer from '../features/data/circleUsageLogSlice';
import featureUsageLogReducer from '../features/data/featureUsageLogSlice';
import donationReducer from '../features/data/donationSlice';
import badgeReducer from '../features/data/badgeSlice';

import legalDocumentsReducer from '../features/data/legalDocsSlice';
import userAcquisitionReducer from '../features/data/userAcquisitionSlice';

import settingsReducer from '../features/settingsSlice';
import authReducer from '../features/authSlice';

export const store = configureStore({
  reducer: {
    stages: stagesReducer,
    stageCircle: stageCircleReducer,
    topics: topicsReducer,
    topicCircle: topicCircleReducer,
    members: membersReducer,
    circles: circlesReducer,
    settings: settingsReducer,
    auth: authReducer,
    blogs: blogsReducer,
    banners: bannersReducer,
    faqs: faqsReducer,
    guides: guidesReducer,
    legalDocuments: legalDocumentsReducer,
    announcements: announcementsReducer,
    goals: goalsReducer,
    events: eventsReducer,
    blogTopics: blogTopicsReducer,
    guideTopics: guideTopicReducer,
    podcasts: podcastsReducer,
    podcastsTopic: podcastsTopicReducer,
    userAcquisitions: userAcquisitionReducer,
    influencersAcquisitions: userAcquisitionReducer,
    convos: convosReducer,
    pollInformation: pollInformationReducer,
    pollResult: pollResultReducer,
    pollQuestionOption: pollQuestionOptionReducer,
    emergencyContacts: emergencyContactsReducer,
    referrals: referralsReducer,
    corporations: corporationReducer,
    courses: courseReducer,
    coursesItem: courseItemReducer,
    coursesQuestion: courseQuestionReducer,
    questionOption: questionOptionReducer,
    sections: sectionReducer,
    streamChat: streamChatReducer,
    overallUsageLog: overallUsageLogReducer,
    circleUsageLog: circleUsageLogReducer,
    featureUsageLog: featureUsageLogReducer,
    donation: donationReducer,
    badge: badgeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
