/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';

export interface IADDTopic {
    topicName: string,
    logoUrl: string,
    topicDesc: string,
}

// reference : https://zoiehealth.azurewebsites.net/swagger/index.html
export interface ITopic {
  id: number,
  topicName: string,
  topicDesc: string,
  createdAt: string,
  logoUrl: string,
  topicCircles: any[],
  userTopics: any[]
}

export interface TermsState {
  topics: ITopic[];
  topicsLoadingStatus: ILoadStatus;
}

const initialState: TermsState = {
  topics: [],
  topicsLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchTopics = createAsyncThunk(
  'topics/fetchTopics',
  async () => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get('/topic/',
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addTopic = createAsyncThunk(
  'members/addTopic',
  async (body:IADDTopic) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/topic', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated permissions');
        }
      }).catch(() => console.log('Unsuccessfully updated permissions'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IEditTopic{
  body:IADDTopic;
  entityId : number;
}
export const editTopic = createAsyncThunk(
  'topics/editTopic',
  async (payload:IEditTopic) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/topic/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated Topic');
        }
      }).catch(() => console.log('Unsuccessfully updated Topic'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IDeleteTopic{
  entityId : number;
}
export const deleteTopic = createAsyncThunk(
  'blogs/editBlog',
  async (payload:IDeleteTopic) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.delete(`/topic/${payload.entityId}`,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully deleted Topic');
        }
      }).catch(() => console.log('Unsuccessfully deleted Topic'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const topicsSlice = createSlice({
  name: 'topics',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopics.pending, (state) => {
        state.topicsLoadingStatus = Status.Loading;
      })
      .addCase(fetchTopics.fulfilled, (state, action) => {
        state.topicsLoadingStatus = Status.Idle;
        state.topics = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = topicsSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectTopics = (state: RootState) => state.topics;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default topicsSlice.reducer;
