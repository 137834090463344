import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { fetchCircles } from '../../features/data/circlesSlice';
import { fetchMembers } from '../../features/data/membersSlice';
import { useAppDispatch } from '../hooks';

export const isLocalHost = ():boolean => {
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '') {
    return true;
  }
  return false;
};

export const getAuthKey = async () => await localStorage.getItem('authKey') ?? '';

function RequireAuth() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  // get User Data on localhost and online when user is authenticated
  if ((localStorage.getItem('authKey') && !isLocalHost()) || isLocalHost()) {
    // Reason for dispatching all these fetches is because the data is used in most pages in app.
    // Fetching data immediately after a successful authentication would make data most likely available before navigation to page/component.
    dispatch(fetchMembers());
    dispatch(fetchCircles());
  }

  if (!localStorage.getItem('authKey') && !isLocalHost()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (<Navigate to="/" state={{ from: location }} />);
  }

  return <Outlet />;
}

export default RequireAuth;
