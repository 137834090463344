/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';

export interface IAddGuide {
    'guideName': string,
    'guideFileUrl': string,
    'guideImageUrl': string,
    'addedBy': number,
}

// reference : https://zoiehealth.azurewebsites.net/swagger/index.html
export interface IGuide {
    'id': number,
    'guideName': string,
    'guideFileUrl': string,
    'guideImageUrl': string,
    'addedBy': number,
    'createdAt': string,
}

export interface TermsState {
  guides: IGuide[];
  guideLoadingStatus: ILoadStatus;
}

const initialState: TermsState = {
  guides: [],
  guideLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchGuides = createAsyncThunk(
  'guides/fetchGuides',
  async () => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get('/guide/',
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addGuide = createAsyncThunk(
  'guides/addGuide',
  async (body:IAddGuide) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/guide', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated Guide');
        }
      }).catch(() => console.log('Unsuccessfully updated Guide'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IEditGuide{
  body:IAddGuide;
  entityId : number;
}
export const editGuide = createAsyncThunk(
  'guides/editGuide',
  async (payload:IEditGuide) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/guide/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated guide');
        }
      }).catch(() => console.log('Unsuccessfully updated guide'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export interface IDeleteGuide{
  entityId : number;
}
export const deleteGuide = createAsyncThunk(
  'guides/deleteGuide',
  async (payload:IDeleteGuide) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.delete(`/guide/${payload.entityId}`,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully deleted guide');
        }
      }).catch(() => console.log('Unsuccessfully deleted guide'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const guidesSlice = createSlice({
  name: 'guides',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchGuides.pending, (state) => {
        state.guideLoadingStatus = Status.Loading;
      })
      .addCase(fetchGuides.fulfilled, (state, action) => {
        state.guideLoadingStatus = Status.Idle;
        state.guides = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = guidesSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectGuides = (state: RootState) => state.guides;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default guidesSlice.reducer;
