/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';

export interface IAddBlog {
    'id': number,
    'blogLink': string,
    'blogImage': string,
    'blogName': string,
    'blogText': string,
    'addedBy': number,
    'createdAt': string,
}

// reference : https://zoiehealth.azurewebsites.net/swagger/index.html
export interface IBlog {
    'id': number,
    'blogLink': string,
    'blogImage': string,
    'blogName': string,
    'blogText': string,
    'addedBy': number,
    'createdAt': string,
}

export interface TermsState {
  blogs: IBlog[];
  blogsLoadingStatus: ILoadStatus;
}

const initialState: TermsState = {
  blogs: [],
  blogsLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchBlogs = createAsyncThunk(
  'blog/fetchBlogs',
  async () => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get('/blog/',
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addBlog = createAsyncThunk(
  'blogs/addBlog',
  async (body:IAddBlog) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/blog', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          response.push(res);
          console.log('successfully updated Blog');
        }
      }).catch(() => console.log('Unsuccessfully updated Blog'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IEditBlog{
  body:IAddBlog;
  entityId : number;
}
export const editBlog = createAsyncThunk(
  'blogs/editBlog',
  async (payload:IEditBlog) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/blog/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated blog');
        }
      }).catch(() => console.log('Unsuccessfully updated blog'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IDeleteBlog{
  entityId : number;
}
export const deleteBlog = createAsyncThunk(
  'blogs/editBlog',
  async (payload:IDeleteBlog) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.delete(`/blog/${payload.entityId}`,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully deleted Blog');
        }
      }).catch(() => console.log('Unsuccessfully deleted Blog'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const blogsSlice = createSlice({
  name: 'blogs',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogs.pending, (state) => {
        state.blogsLoadingStatus = Status.Loading;
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.blogsLoadingStatus = Status.Idle;
        state.blogs = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = blogsSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectBlogs = (state: RootState) => state.blogs;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default blogsSlice.reducer;
