/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { Status } from '../../constants/enums';
import { ILoadStatus } from '../../constants/interfaces';
import { RootState } from '../../redux/store';
import { isStatusOK } from './emergencyContactsSlice';

const ENDPOINT = 'zoieReferral';
const resourceText = 'zoie Referral';
export const AUTH_KEY = localStorage.getItem('authKey') ?? '';
const headers = { Authorization: `Basic ${AUTH_KEY}` };

interface IReferred {
  id: number,
  firstName: string,
  lastName: string,
  userName: string,
}

interface IReferrer extends IReferred {}

export interface IReferral {
    id: number,
    referrerId: number,
    referredId : number,
    datereferred: string,
    referred: IReferred
    referrer: IReferrer
}

export interface IAddReferral {
    description: string,
    phoneNumber : string,
    title: string,
}

export interface IDeleteReferral {
  id: number;
}
interface IEditReferral {
  body: IAddReferral;
  entityId : number;
}

export interface ReferralsState {
  referrals: IReferral[];
  referralsLoadingStatus: ILoadStatus;
}

const initialState: ReferralsState = {
  referrals: [],
  referralsLoadingStatus: Status.Idle,
};

export const fetchReferrals = createAsyncThunk(
  `${ENDPOINT}/fetchEmergencyContact`, async (id? : number| null) => {
    let response: any[] = [];
    await APP_URL.get(`/${ENDPOINT}${id ? `/${id}` : ''}`, {
      headers,
    })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      })
      .catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addReferral = createAsyncThunk(
  `${ENDPOINT}/addReferral`,
  async (body:IAddReferral) => {
    const response:any[] = [];
    await APP_URL.post(`/${ENDPOINT}`, body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully added ${resourceText}`);
        }
      }).catch((e) => console.log(e));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const deleteReferral = createAsyncThunk(
  `${ENDPOINT}/deleteReferral`,
  async (body:IDeleteReferral) => {
    let response:any[] = [];
    await APP_URL.delete(`/${ENDPOINT}/${body.id}/`,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      }).catch((res) => { response = res.data; });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const editReferral = createAsyncThunk(
  `${ENDPOINT}/editReferral`,
  async (payload:IEditReferral) => {
    const response:any[] = [];
    await APP_URL.put(`/${ENDPOINT}/${payload.entityId}`, payload.body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully updated ${resourceText}`);
        }
      }).catch(() => console.log(`Unsuccessfully ${resourceText}`));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const referralsSlice = createSlice({
  name: 'referrals',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchReferrals.pending, (state) => {
      state.referralsLoadingStatus = Status.Loading;
    })
      .addCase(fetchReferrals.fulfilled, (state, action) => {
        state.referralsLoadingStatus = Status.Idle;
        state.referrals = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = referralsSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectReferrals = (state: RootState) => state.referrals;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default referralsSlice.reducer;
