/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { Status } from '../../constants/enums';
import { ILoadStatus } from '../../constants/interfaces';
import { RootState } from '../../redux/store';
import { isStatusOK } from './emergencyContactsSlice';

const ENDPOINT = 'course';
const resourceText = 'Course';
export const AUTH_KEY = localStorage.getItem('authKey') ?? '';
export const headers = { Authorization: `Basic ${AUTH_KEY}` };

interface ICourseSchema {
    courseName: string,
    courseDescription: string,
    courseImage: string,
    themeId: number,
    theme: any[]
}

export interface ICourse extends ICourseSchema {
    id: number,
}

export interface IAddCourse extends ICourseSchema {}

export interface IDeleteCourse {
  id: number;
}
export interface IStoredCourse {
  stored: any;
}

interface IEditCourse {
  body: IAddCourse;
  entityId : number;
}

export interface CourseState {
  courses: ICourse[];
  storedValue: IStoredCourse[] ;
  coursesLoadingStatus: ILoadStatus;
}

const initialState: CourseState = {
  courses: [],
  storedValue: [],
  coursesLoadingStatus: Status.Idle,
};

export const fetchCourses = createAsyncThunk(
  `${ENDPOINT}/fetchCourses`, async (id? : number| null) => {
    let response: ICourse[] = [];
    await APP_URL.get(`/${ENDPOINT}${id ? `/${id}` : ''}`, {
      headers,
    })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      })
      .catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addCourse = createAsyncThunk(
  `${ENDPOINT}/addCourse`,
  async (body:IAddCourse) => {
    const response:any[] = [];
    await APP_URL.post(`/${ENDPOINT}`, body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully added ${resourceText}`);
        }
      }).catch(() => {
        throw new Error('error');
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const storedCourseId = createAsyncThunk(
  `${ENDPOINT}/storedCourseId`,
  (courseIdStored : any) => {
    let response:IStoredCourse[] = [];
    response = courseIdStored;
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const deleteCourse = createAsyncThunk(
  `${ENDPOINT}/deleteCourse`,
  async (body:IDeleteCourse) => {
    let response:any[] = [];
    await APP_URL.delete(`/${ENDPOINT}/${body.id}/`,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      }).catch((res) => { response = res.data; });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const editCourse = createAsyncThunk(
  `${ENDPOINT}/editCourse`,
  async (payload:IEditCourse) => {
    const response:any[] = [];
    await APP_URL.put(`/${ENDPOINT}/${payload.entityId}`, payload.body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully updated ${resourceText}`);
        }
      }).catch(() => console.log(`Unsuccessfully ${resourceText}`));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const courseSlice = createSlice({
  name: ENDPOINT,
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchCourses.pending, (state) => {
      state.coursesLoadingStatus = Status.Loading;
    })
      .addCase(storedCourseId.fulfilled, (state, action) => {
        state.storedValue = action.payload;
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        state.coursesLoadingStatus = Status.Idle;
        state.courses = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = courseSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectCourse = (state: RootState) => state.courses;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default courseSlice.reducer;
