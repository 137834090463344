/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../redux/store';

// To manage display of buttons on the top right side of the tabs on Content & User Management Pages
export enum RightTabOptions {
  members = 'MEMBERS',
  companies = 'COMPANIES',
  emergencyAssistants = 'emergencyAssistants',
  medicalPractioners = 'MEDICAL_PRACTIONERS',
  admin = 'ADMIN',
  circles = 'CIRCLES',
  stagesAndTopics = 'STAGES_AND_TOPICS',
  personalizedPathways = 'PERSONALIZED_PATHWAYS',
  blogs = 'BLOGS',
  podcasts = 'PODCASTS',
  guides = 'GUIDES',
  give = 'GIVE',
  polls = 'POLLS',
  ZoieBadge = 'ZOIE_BADGE',
  none = '', // should have none to display or reset to no top right buttons on Content & User Management Pages
}
// Keys and values should be unique (important)
// Modals and/or Drawers
export enum DisplayedModalOptions {
  // User Management
  importBulkMembers = 'IMPORT_BULK_MEMBERS',
  Member = 'MEMBER',
  Company = 'COMPANY',
  importBulkPractioners = 'IMPORT_BULK_PRACTIONERS',
  medicalPractitioner = 'MEDICAL_PRACTITIONER',
  importBulkAdmin = 'IMPORT_BULK_ADMIN',
  addNewAdmin = 'ADD_NEW_ADMIN',
  memberProfile= 'MEMBER_PROFILE', // this is the member profile
  // Content Management
  Announcements = 'ANNOUNCEMENTS',
  Circle = 'CIRCLE',
  Stages = 'STAGES',
  Topics = 'TOPICS',
  HomeBanner = 'HomeBannner',
  Faq = 'FAQ',
  LegalDocuments = 'LEGAL_DOCUMENTS',
  // Digital classes / personalized patheways
  PersonalizedPathways = 'PERSONALIZED_PATHWAYS',
  PersonalizedSections= 'PERSONALIZED_SECTIONS',
  PersonalizedMedia= 'PERSONALIZED_MEDIA',
  PersonalizedActivity= 'PERSONALIZED_ACTIVITY',
  PersonalizedMediaImage= 'PERSONALIZED_MEDIA_Image',
  PersonalizedMediaEbook= 'PERSONALIZED_MEDIA_Ebook',
  Blog = 'BLOG',
  Guide = 'GUIDE',
  Give = 'GIVE',
  Podcast = 'PODCAST',
  emergencyAssistants = 'emergencyAssistants',
  addNewOrganization = 'ADD_NEW_ORGANIZATION',
  Feed = 'FEED',
  Event = 'EVENT',
  ConvoEvent = 'CONVO_EVENT',
  EventSelection = 'EVENT_SELECTION',
  Polls = 'POLLS',
  ZoieBadge = 'ZOIE_BADGE',
  none = '',
}

export type ModalTypes = 'VIEW' |'EDIT' | 'ADD' | 'DELETE';

export enum ModalTypeOptions {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  ADD = 'ADD',
  DELETE = 'DELETE',
}
export interface IModalType {
  entityId? : number | string;
}
interface IDisplayModal {
  type?: ModalTypes;
  option: DisplayedModalOptions;
  entityProfileId?: string | number | null;
}
export interface ISettingsState {
  rightTabButtons: RightTabOptions;
  displayedModal : IDisplayModal;
}

const initialState: ISettingsState = {
  rightTabButtons: RightTabOptions.members,
  displayedModal: { option: DisplayedModalOptions.none, entityProfileId: null },
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setRightTabButtons: (state, { payload }: PayloadAction<RightTabOptions>) => {
      state.rightTabButtons = payload;
    },
    setDisplayedModal: (state, { payload }: PayloadAction<IDisplayModal>) => {
      state.displayedModal = { ...state.displayedModal, ...payload };
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

});
export const { setRightTabButtons, setDisplayedModal } = settingsSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectSettings = (state: RootState) => state.settings;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default settingsSlice.reducer;
