/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { Status } from '../../constants/enums';
import { ILoadStatus } from '../../constants/interfaces';
import { RootState } from '../../redux/store';
import { IAddAnnouncement } from '../../components/Announcements/Announcements';

export interface IAnnouncement {
  id: number;
  title: string;
  message: string;
  image: string;
  dateCreated: string;
}

export interface IDeleteAnnouncement {
  id: number;
}
interface IEditAnnouncement{
  body: IAddAnnouncement;
  entityId : number;
}

export interface AnnouncementState {
  announcements: IAnnouncement[];
  announcementsLoadingStatus: ILoadStatus;
}

const initialState: AnnouncementState = {
  announcements: [],
  announcementsLoadingStatus: Status.Idle,
};

export const fetchAnnouncements = createAsyncThunk('announcement/fetchAnnouncements', async () => {
  const base64String = (await localStorage.getItem('authKey')) ?? '';
  let response: any[] = [];
  await APP_URL.get('/announcement', {
    headers: { Authorization: `Basic ${base64String}` },
  })
    .then((res) => {
      if (res.status === 200) {
        response = res.data;
      }
    })
    .catch(() => {});
  // The value we return becomes the `fulfilled` action payload
  return response;
});

export const addAnnouncement = createAsyncThunk(
  'announcement/addAnnouncement',
  async (body:IAddAnnouncement) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/announcement', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully added announcement');
        }
      }).catch(() => console.log('Unsuccessfully added announcement'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const deleteAnnouncement = createAsyncThunk(
  'announcement/deleteAnnouncement',
  async (body:IDeleteAnnouncement) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.delete(`/announcement/${body.id}/`,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          response = res.data;
        }
      }).catch((res) => { response = res.data; });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const editAnnouncement = createAsyncThunk(
  'announcement/editAnnouncement',
  async (payload:IEditAnnouncement) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/announcement/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated announcement');
          // window.location.href = '/contentmanagement';
        }
      }).catch(() => console.log('Unsuccessfully updated announcement'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const announcementsSlice = createSlice({
  name: 'announcements',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchAnnouncements.pending, (state) => {
      state.announcementsLoadingStatus = Status.Loading;
    })
      .addCase(fetchAnnouncements.fulfilled, (state, action) => {
        state.announcementsLoadingStatus = Status.Idle;
        state.announcements = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = announcementsSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectAnnouncements = (state: RootState) => state.announcements;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default announcementsSlice.reducer;
