/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';

export interface IADDGoal {
    goalName: string,
    goalDesc: string,
    logoUrl: string,
    subHeader: string,
}

// reference : https://zoiehealth.azurewebsites.net/swagger/index.html
export interface IGoal {
    id: number,
    goalName: string,
    goalDesc: string,
    createdAt: string,
    logoUrl: string,
    subHeader: string,
}

export interface TermsState {
  goals: IGoal[];
  goalsLoadingStatus: ILoadStatus;
}

const initialState: TermsState = {
  goals: [],
  goalsLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchGoals = createAsyncThunk(
  'goals/fetchGoals',
  async () => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.get('/goal/',
      {
        headers:
      { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addGoal = createAsyncThunk(
  'goals/addGoal',
  async (body:IADDGoal) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/goal', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated Goals');
        }
      }).catch(() => console.log('Unsuccessfully updated Goals'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IEditGoal{
  body:IADDGoal;
  entityId : number;
}
export const editGoal = createAsyncThunk(
  'goals/editGoal',
  async (payload:IEditGoal) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/goal/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated Goal');
          // window.location.href = '/contentmanagement';
        }
      }).catch(() => console.log('Unsuccessfully updated Goal'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const goalsSlice = createSlice({
  name: 'goals',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchGoals.pending, (state) => {
        state.goalsLoadingStatus = Status.Loading;
      })
      .addCase(fetchGoals.fulfilled, (state, action) => {
        state.goalsLoadingStatus = Status.Idle;
        state.goals = action.payload;
      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = goalsSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectGoals = (state: RootState) => state.goals;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default goalsSlice.reducer;
