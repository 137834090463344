/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { Status } from '../../constants/enums';
import { ILoadStatus } from '../../constants/interfaces';
import { RootState } from '../../redux/store';

export interface IConvo {
    id: number,
    sessionName: string,
    endTime : number,
    startTime: number,
    meetingToken : string,
    dailyCoLink : string,
    createdAt: string,
    convoImage: string,
    convoTitle: string,
    convoDescription : string,
}

export interface IAddConvo {
    sessionName: string,
    endTime : number,
    startTime: number,
    meetingToken : string,
    dailyCoLink : string,
    convoImage: string,
    convoTitle: string,
    convoDescription : string
}

export interface IDeleteConvoEvent {
  id: number;
}
interface IEditConvo{
  body: IAddConvo;
  entityId : number;
}

export interface ConvosState {
  convos: IConvo[];
  convosLoadingStatus: ILoadStatus;
}

const initialState: ConvosState = {
  convos: [],
  convosLoadingStatus: Status.Idle,
};

export const fetchConvos = createAsyncThunk(
  'audioSession/fetchConvos', async (id? : number| null) => {
    const base64String = (await localStorage.getItem('authKey')) ?? '';
    let response: any[] = [];
    await APP_URL.get(`/AudioSession${id ? `/${id}` : ''}`, {
      headers: { Authorization: `Basic ${base64String}` },
    })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      })
      .catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addConvo = createAsyncThunk(
  'audioSession/addConvo',
  async (body:IAddConvo) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.post('/audioSession', body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully added audio session');
        }
      }).catch((e) => console.log(e));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const deleteConvo = createAsyncThunk(
  'audioSession/deleteConvo',
  async (body:IDeleteConvoEvent) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    let response:any[] = [];
    await APP_URL.delete(`/audioSession/${body.id}/`,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          response = res.data;
        }
      }).catch((res) => { response = res.data; });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const editConvo = createAsyncThunk(
  'audioSession/editConvo',
  async (payload:IEditConvo) => {
    const base64String = await localStorage.getItem('authKey') ?? '';
    const response:any[] = [];
    await APP_URL.put(`/audioSession/${payload.entityId}`, payload.body,
      {
        headers: { Authorization: `Basic ${base64String}` },
      })
      .then((res) => {
        if ((res.status >= 200) && res.status < 300) {
          console.log('successfully updated convo');
        }
      }).catch(() => console.log('Unsuccessfully updated convo'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const convosSlice = createSlice({
  name: 'convos',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchConvos.pending, (state) => {
      state.convosLoadingStatus = Status.Loading;
    })
      .addCase(fetchConvos.fulfilled, (state, action) => {
        state.convosLoadingStatus = Status.Idle;
        state.convos = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = convosSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectConvos = (state: RootState) => state.convos;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default convosSlice.reducer;
