/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { Status } from '../../constants/enums';
import { ILoadStatus } from '../../constants/interfaces';
import { RootState } from '../../redux/store';
import { isStatusOK } from './emergencyContactsSlice';

const ENDPOINT = 'corporation';
const resourceText = 'Corporation';
export const AUTH_KEY = localStorage.getItem('authKey') ?? '';
export const headers = { Authorization: `Basic ${AUTH_KEY}` };

interface ICorporationSchema {
  corpName: string,
  corpCode: string,
  corpPic : string,
  corpDesc: string,
  stripeId: string,
  userAuths: any[]
}

export interface ICorporation extends ICorporationSchema {
    id: number,
}

export interface IAddCorporation extends ICorporationSchema {}

export interface IDeleteCorporation {
  id: number;
}

interface IEditCompany {
  body: IAddCorporation;
  entityId : number;
}

export interface CorporationState {
  corporations: ICorporation[];
  corporationsLoadingStatus: ILoadStatus;
}

const initialState: CorporationState = {
  corporations: [],
  corporationsLoadingStatus: Status.Idle,
};

export const fetchCorporations = createAsyncThunk(
  `${ENDPOINT}/fetchCorporations`, async (id? : number| null) => {
    let response: ICorporation[] = [];
    await APP_URL.get(`/${ENDPOINT}${id ? `/${id}` : ''}`, {
      headers,
    })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      })
      .catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addCorporation = createAsyncThunk(
  `${ENDPOINT}/addCorporation`,
  async (body:IAddCorporation) => {
    const response:any[] = [];
    await APP_URL.post(`/${ENDPOINT}`, body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully added ${resourceText}`);
        }
      }).catch(() => {
        throw new Error('error');
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const deleteCorporation = createAsyncThunk(
  `${ENDPOINT}/deleteCorporation`,
  async (body:IDeleteCorporation) => {
    let response:any[] = [];
    await APP_URL.delete(`/${ENDPOINT}/${body.id}/`,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      }).catch((res) => { response = res.data; });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const editCorporation = createAsyncThunk(
  `${ENDPOINT}/editCorporation`,
  async (payload:IEditCompany) => {
    const response:any[] = [];
    await APP_URL.put(`/${ENDPOINT}/${payload.entityId}`, payload.body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully updated ${resourceText}`);
        }
      }).catch(() => console.log(`Unsuccessfully ${resourceText}`));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const corporationSlice = createSlice({
  name: ENDPOINT,
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchCorporations.pending, (state) => {
      state.corporationsLoadingStatus = Status.Loading;
    })
      .addCase(fetchCorporations.fulfilled, (state, action) => {
        state.corporationsLoadingStatus = Status.Idle;
        state.corporations = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = corporationSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectCorporation = (state: RootState) => state.corporations;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default corporationSlice.reducer;
