/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { ILoadStatus } from '../../constants/interfaces';
import { Status } from '../../constants/enums';
import { RootState } from '../../redux/store';
import { headers } from './corporationSlice';
import { isStatusOK } from './emergencyContactsSlice';

const ENDPOINT = '/userauth';

export interface IADDMEMBER {
  authSystemId: string,
  emailAddress: string,
  firstName: string,
  lastName: string,
  userName: string,
  phoneNumber: string,
  city: string,
  country: string,
  stripeId: string,
  permissionLevel: number,
  password: string,
  profilePic: string,
  getStreamId: string,
}
export interface IMember {
  id: number
  firstName: string
  lastName: string
  userName: string
  phoneNumber: string
  authSystemId: string
  emailAddress: string
  stripeId: string
  corpId: any
  permissionLevel: number
  createdAt: string
  password: string
  country: string
  city: string
  profilePic: string
  getStreamId: string
  stripeCustomerId: any
  subscriptionLevel: number
  auraSubscriptionId: any
  isAuraActive: boolean
  stripeSubscriptionId: any
  refferalCode: string
  bookingId: any
  corp: any
  announcements: any[]
  auraDetails: any[]
  banners: any[]
  blogs: any[]
  circleMembers: any[]
  circles: any[]
  events: any[]
  guides: any[]
  legalDocs: any[]
  notificationAddedbyNavigations: any[]
  notificationRecipients: any[]
  notificationSenders: any[]
  passwordResets: any[]
  podcasts: any[]
  pollInformations: any[]
  pollQuestionOptions: any[]
  pollResults: any[]
  userDevices: any[]
  userGoals: any[]
  userStages: any[]
  userTopics: any[]
  zoieConnectionInvitees: any[]
  zoieConnectionReceivers: any[]
  zoieReferralReferreds: any[]
  zoieReferralReferrers: any[]
}

export interface IADDMember {
  stageName:string;
  logoUrl: string,
  subHeader: any,
  stageDesc:string;
}

export const fetchMembers = createAsyncThunk(
  'members/fetchMembers',
  async () => {
    let response:any[] = [];
    await APP_URL.get(`${ENDPOINT}/`,
      {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          response = res.data;
        }
      }).catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addMember = createAsyncThunk(
  'members/addMember',
  async (body:IADDMEMBER) => {
    const response:any[] = [];
    await APP_URL.post(ENDPOINT, body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response.push(res);
        }
      }).catch((err) => {
        response.push(err);
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const usernameOrEmailExists = createAsyncThunk(
  'members/usernameOrEmailExists',
  async (body:IADDMEMBER) => {
    const response:any[] = [];
    await APP_URL.post(`${ENDPOINT}/doesUserNameOrEmailExist`, body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response.push(res);
        }
      }).catch((err) => {
        response.push(err);
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export interface IAddUsersFromExcel {
  corpId : number;
  typeId : number;
  file: any;
}
export const addUsersFromExcel = createAsyncThunk(
  'members/addUsersFromExcel',
  async (body: FormData) => {
    const response:any[] = [];
    await APP_URL.post(`${ENDPOINT}/addUsersFromExcel`, body,
      {
        headers: { ...headers, 'Content-Type': 'multipart/form-data' },
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response.push(res);
        }
      }).catch((err) => {
        response.push(err);
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export interface IEditPartialMember {
  id: number,
  emailAddress: string,
  firstName: string,
  lastName: string,
  userName: string,
  phoneNumber: string,
  city: string,
  country: string,
}

export interface IEditMember{
  body: IEditPartialMember;
  entityId : number;
}
export const editMember = createAsyncThunk(
  'members/editMember',
  async (payload:IEditMember) => {
    const response:any[] = [];
    await APP_URL.put(`${ENDPOINT}/update-profile/${payload.entityId}`, payload.body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log('successfully edited Member');
        }
      }).catch(() => console.log('Unsuccessfully edited Member'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const updateMembersPermission = createAsyncThunk(
  'members/updateMembersPermission',
  async (body:any[]) => {
    const response:any[] = [];
    await APP_URL.put(`${ENDPOINT}/admin-multiple`, body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log('successfully updated permissions');
        }
      }).catch(() => console.log('Unsuccessfully updated permissions'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

interface IDeleteMember{
  entityId : number;
}
export const deleteMember = createAsyncThunk(
  'member/deleteMember',
  async (payload:IDeleteMember) => {
    const response:any[] = [];
    await APP_URL.delete(`${ENDPOINT}/wipe-data/${payload.entityId}`,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log('successfully deleted Member');
        }
      }).catch(() => console.log('Unsuccessfully deleted Member'));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export interface TermsState {
  members: IMember[];
  membersLoadingStatus: ILoadStatus;
}

const initialState: TermsState = {
  members: [],
  membersLoadingStatus: Status.Idle,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTermRelations(termId))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const membersSlice = createSlice({
  name: 'members',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchMembers.pending, (state) => {
        state.membersLoadingStatus = Status.Loading;
      })
      .addCase(fetchMembers.fulfilled, (state, action) => {
        state.membersLoadingStatus = Status.Idle;
        state.members = action.payload;
      })
      .addCase(updateMembersPermission.pending, (state) => {
        state.membersLoadingStatus = 'loading';
      })
      .addCase(updateMembersPermission.fulfilled, () => {

      });
  },
});
// eslint-disable-next-line no-empty-pattern
export const { } = membersSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectMembers = (state: RootState) => state.members;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default membersSlice.reducer;
