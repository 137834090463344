/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { Status } from '../../constants/enums';
import { ILoadStatus } from '../../constants/interfaces';
import { RootState } from '../../redux/store';
import { isStatusOK } from './emergencyContactsSlice';

const ENDPOINT = 'courseItem';
const resourceText = 'CourseItem';
export const AUTH_KEY = localStorage.getItem('authKey') ?? '';
export const headers = { Authorization: `Basic ${AUTH_KEY}` };

interface ICourseItemSchema {
    courseId: number,
    itemType: string,
    sectionId: number,
    order: number,
    itemUrl: string,
    course: any[]
}

export interface ICourseItem extends ICourseItemSchema {
    id: number,
}

export interface IAddCourseItem{
  courseId: number | undefined,
  itemType: string,
  sectionId: number | undefined,
  order: number,
  itemUrl: string
}

export interface IDeleteCourseItem {
  id: number;
}
export interface IStoredFileName {
  stored: any;
}

interface IEditCourseItem {
  body: IAddCourseItem;
  entityId : number;
}

export interface CourseItemState {
  coursesItem: ICourseItem[];
  storedName: IStoredFileName[] ;
  coursesItemLoadingStatus: ILoadStatus;
}

const initialState: CourseItemState = {
  coursesItem: [],
  storedName: [],
  coursesItemLoadingStatus: Status.Idle,
};

export const fetchCourseItems = createAsyncThunk(
  `${ENDPOINT}/fetchCourseItems`, async (id? : number| null) => {
    let response: ICourseItem[] = [];
    await APP_URL.get(`/${ENDPOINT}${id ? `/${id}` : ''}`, {
      headers,
    })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      })
      .catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const storedFileName = createAsyncThunk(
  `${ENDPOINT}/storedFileName`,
  (fileNameStored : any) => {
    let response:IStoredFileName [] = [];
    response = fileNameStored;
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addCourseItem = createAsyncThunk(
  `${ENDPOINT}/addCourseItem`,
  async (body:IAddCourseItem) => {
    const response:any[] = [];
    await APP_URL.post(`/${ENDPOINT}`, body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully added ${resourceText}`);
        }
      }).catch(() => {
        throw new Error('error');
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const deleteCourseItem = createAsyncThunk(
  `${ENDPOINT}/deleteCourseItem`,
  async (body:IDeleteCourseItem) => {
    let response:any[] = [];
    await APP_URL.delete(`/${ENDPOINT}/${body.id}/`,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      }).catch((res) => { response = res.data; });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const editCourseItem = createAsyncThunk(
  `${ENDPOINT}/editCourseItem`,
  async (payload:IEditCourseItem) => {
    const response:any[] = [];
    await APP_URL.put(`/${ENDPOINT}/${payload.entityId}`, payload.body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully updated ${resourceText}`);
        }
      }).catch(() => console.log(`Unsuccessfully ${resourceText}`));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const courseItemSlice = createSlice({
  name: ENDPOINT,
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchCourseItems.pending, (state) => {
      state.coursesItemLoadingStatus = Status.Loading;
    })
      .addCase(storedFileName.fulfilled, (state, action) => {
        state.storedName = action.payload;
      })
      .addCase(fetchCourseItems.fulfilled, (state, action) => {
        state.coursesItemLoadingStatus = Status.Idle;
        state.coursesItem = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = courseItemSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectCourseItem = (state: RootState) => state.coursesItem;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default courseItemSlice.reducer;
