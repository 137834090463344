/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_URL } from '../../constants/helpers/axios';
import { Status } from '../../constants/enums';
import { ILoadStatus } from '../../constants/interfaces';
import { RootState } from '../../redux/store';
import { isStatusOK } from './emergencyContactsSlice';

const ENDPOINT = 'section';
const resourceText = 'Section';
export const AUTH_KEY = localStorage.getItem('authKey') ?? '';
export const headers = { Authorization: `Basic ${AUTH_KEY}` };

interface ICourseItem {
    id: number
    courseId: number
    itemType: string
    sectionId: number
    order: number
    dateCreated: string
    itemUrl: string
    guid: string
    course: any
}
interface ICourseQuestion {
  id: number
  questionTitle: string
  courseId: number
  sectionId: number
  dateCreated: string
  order: number
  guid: string
  course: any
  answers: any[]
  questionOptions: any[]
}

interface ISectionSchema {
    sectionName: string,
    sectionDescription: string,
    courseId: number,
    courseItems: ICourseItem[],
    courseQuestions: ICourseQuestion[],
    course: any[]
}

export interface ISection extends ISectionSchema {
    id: number,
}
export interface ISectionCourse extends ISectionSchema {
    id: number,
}
export interface ISectionCourseById extends ICourseQuestion {
  section: any,
  itemType: string,
  itemUrl: string,
}

export interface IDeleteSection {
  id: number;
}
export interface IAddSection {
  id: number;
  sectionName: string,
  sectionDescription: string,
  courseId: number,
}

interface IEditSection {
  body: IAddSection;
  entityId : number;
}

export interface SectionState {
  sections: ISectionCourse[];
  SectionsByCourseId : ISectionCourseById[];
  allSections: ISection[];
  sectionsLoadingStatus: ILoadStatus;
  SectionsByCourseIdLoadingStatus: ILoadStatus;
  allSectionsLoadingStatus: ILoadStatus;
}

const initialState: SectionState = {
  sections: [],
  SectionsByCourseId: [],
  allSections: [],
  sectionsLoadingStatus: Status.Idle,
  SectionsByCourseIdLoadingStatus: Status.Idle,
  allSectionsLoadingStatus: Status.Idle,
};

export const fetchSections = createAsyncThunk(
  `${ENDPOINT}/fetchSections`, async (id? : number| null) => {
    let response: ISection[] = [];
    await APP_URL.get(`/${ENDPOINT}${id ? `/${id}` : ''}`, {
      headers,
    })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      })
      .catch(() => {});
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const fetchSectionsByCourseId = createAsyncThunk(
  `${ENDPOINT}/fetchSectionsByCourseId`, async (courseId? : number| null) => {
    let response: ISectionCourse[] = [];
    console.log('id', courseId);
    await APP_URL.get(`/${ENDPOINT}/GetByCourseId${courseId ? `/?courseId=${courseId}` : ''}`, {
      headers,
    })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      })
      .catch(() => {});

    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);
export const fetchGetAllCourseItems = createAsyncThunk(
  `${ENDPOINT}/fetchGetAllCourseItems`, async (sectionId? : number| null) => {
    let response: ISectionCourseById[] = [];
    console.log('GetAllid', sectionId);
    await APP_URL.get(`/${ENDPOINT}/GetAllCourseItems${sectionId ? `/?sectionId=${sectionId}` : ''}`, {
      headers,
    })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      })
      .catch(() => {});

    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const addSection = createAsyncThunk(
  `${ENDPOINT}/addSection`,
  async (body:IAddSection) => {
    const response:any[] = [];
    await APP_URL.post(`/${ENDPOINT}`, body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully added ${resourceText}`);
        }
      }).catch(() => {
        throw new Error('error');
      });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const deleteSection = createAsyncThunk(
  `${ENDPOINT}/deleteSection`,
  async (body:IDeleteSection) => {
    let response:any[] = [];
    await APP_URL.delete(`/${ENDPOINT}/${body.id}/`,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          response = res.data;
        }
      }).catch((res) => { response = res.data; });
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const editSection = createAsyncThunk(
  `${ENDPOINT}/editSection`,
  async (payload:IEditSection) => {
    const response:any[] = [];
    await APP_URL.put(`/${ENDPOINT}/${payload.entityId}`, payload.body,
      {
        headers,
      })
      .then((res) => {
        if (isStatusOK(res.status)) {
          console.log(`successfully updated ${resourceText}`);
        }
      }).catch(() => console.log(`Unsuccessfully ${resourceText}`));
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const sectionSlice = createSlice({
  name: ENDPOINT,
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(fetchSectionsByCourseId.pending, (state) => {
      state.sectionsLoadingStatus = Status.Loading;
    })
      .addCase(fetchSectionsByCourseId.fulfilled, (state, action) => {
        state.sectionsLoadingStatus = Status.Idle;
        state.sections = action.payload;
      })
      .addCase(fetchGetAllCourseItems.pending, (state) => {
        state.SectionsByCourseIdLoadingStatus = Status.Loading;
      })
      .addCase(fetchGetAllCourseItems.fulfilled, (state, action) => {
        state.SectionsByCourseIdLoadingStatus = Status.Idle;
        state.SectionsByCourseId = action.payload;
      })
      .addCase(fetchSections.pending, (state) => {
        state.allSectionsLoadingStatus = Status.Loading;
      })
      .addCase(fetchSections.fulfilled, (state, action) => {
        state.allSectionsLoadingStatus = Status.Idle;
        state.allSections = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = sectionSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.authentication.value)`
export const selectSection = (state: RootState) => state.sections;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default sectionSlice.reducer;
